import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import { SEO } from "../components/common";
import Layout from "../components/common/Layout/Layout";
import { SiteInfo, Contact, Steps, Text } from "../components/landing";
import App from "../components/App";

// import BannerAds from "../components/BannerAds"
const Home = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <>
      <SEO
        lang={language}
        title={t("home:title")}
        description={
          t("home:Video Downloader") +
          ", " +
          t("home:Online Video Downloader[text]")
        }
        keywords={[
          "save video",
          t("home:title"),
          t("home:Download Video"),
          t("home:Online Video Downloader"),
          t("home:Facebook Video Downloader"),
          t("home:Dailymotion Video Downloader"),
          "video downloader",
          "online download videos",
        ]}
      />
      {/* <BannerAds /> */}
      <App titleContent={t("home:Online Video Downloader")} />
      <div id="container-3c14e353698e00f86bcd4b1ecabc0f41"></div>
      <SiteInfo isStatic={false} />
      <Steps />
      <Text />
      <Contact />
    </>
  );
};

const customProps = {
  localeKey: "home", // same as file name in src/i18n/translations/your-lang/index.js
};

export default Layout(customProps)(Home);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "home", "faq"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
